<template>
  <div>
    <Details />
  </div>
</template>

<script>
import Details from '@/components/application/Details';

export default {
  name: 'applications-single-details-applicant-page',
  components: {
    Details,
  },
};
</script>
